import { TypographyH2 } from "../ui/typography/h2"
import truckLogo from "@/assets/pngs/marketingLogoTruck.png"
import truck from "@/assets/pngs/marketingTruck.png"
import doingGood from "@/assets/pngs/doingGood.png"
import home1 from "@/assets/pngs/home/home-1.png"
import home2 from "@/assets/pngs/home/home-2.png"
import home3 from "@/assets/pngs/home/home-3.png"
import { Button } from "../ui/button"
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card"
import { useLabels } from "@/context/LabelContext"
import { Badge } from "@/components/ui/badge"
import { formatDateForURL } from "@/lib/formatDateForUrl"
import {PromoHeroSection} from "@/components/PromoHeroSection"

const MarketingSections = ({ locations, handlePromoSubmit, email, setEmail }) => {
  const { getLabel } = useLabels()

  return (
    <>
      <PromoHeroSection email={email} setEmail={setEmail} handlePromoSubmit={handlePromoSubmit} />
      <section className="p-10 h-fit lg:w-[80%] bg-primary rounded mx-auto lg:mt-20 relative bg-white gap-8 flex flex-col">
        <div className="flex gap-2 justify-center">
          <p className="text-black text-3xl font-regular text-center">
          Find your next parking spot near <span className="text-orange-500 text-3xl font-regular text-center italic text-muted-foreground">Vail, Colorado</span>
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {
          locations.length > 0 && locations.map((location, i) => (
            <MarketingCard key={`${location.facility.id}-${i}`} data={location} />
          ))
        }
      </div>
    </section>
    </>
  )
}

export default MarketingSections

const badges = (tags) => {
  if (tags?.length === 0) return null
  return (
    <div className="flex mt-1">
      {tags?.map((tag, i) => (
        <Badge key={`${tag.name}-${i}`} className="capitalize mr-1">{tag.name}</Badge>
      ))}
    </div>
  )
}

const MarketingCard = ({ data }) => {
  const configureSearch = (facilityId) => {
    location.href = `/search?facility=${facilityId}&checkinDate=${formatDateForURL(new Date())}&checkoutDate=${formatDateForURL(new Date())}`
  }

  return (
    <Card role="button" onClick={() => configureSearch(data.facility.id)} className="w-full flex flex-col h-full transition-all duration-300  hover:scale-105 cursor-pointer">
      <div className="relative aspect-[4/3] w-full overflow-hidden rounded-t-lg">
        <img
          src={data.images[0]?.url || "https://placehold.co/250"}
          alt={data.name}
          className="absolute inset-0 h-full w-full object-cover transition-all duration-300"
        />
      </div>
      <CardContent className="flex-grow p-4 flex flex-col">
        <h3 className="text-xl font-semibold mb-2">{data.facility.name}</h3>
        {badges(data.lot.tags)}
      </CardContent>
      <CardFooter className="p-4 pt-0 mt-auto">
        <Button variant="link" className="pointer-events-none underline ml-auto">
          Book Now
        </Button>
      </CardFooter>
    </Card>
  )
}
