import { Button } from "@/components/ui/button"
import ctaImage from "@/assets/pngs/2skis.webp"
import ctaImageMobile from "@/assets/pngs/2skisMobile.webp"
import { useEmailSignup } from "@/hooks/useEmailSignup"
import { PromoCode } from "@/pages/PromoPopup/PromoPopup"

const LOCAL_STORAGE_CODE = "PromoHero"
const NEW20 = "NEW20"
const PROMO_CONTENT = {
  new: {
    title: "Reserve Early, Ski Faster",
    description: "Sign up to get your exclusive code and save on your first booking. Your next adventure starts here!",
    buttonText: "Get Offer",
    emailPlaceholder: "Email address"
  },
  confirmed: {
    title: "Welcome to the Club!",
    description: "Here is your exclusive discount code. Use it at checkout to get 20% off your next booking."
  },
  returning: {
    title: "Seamless Parking, First Chair Guaranteed",
    description: "Discover why Spotsurfer is the easiest way to park at ski resorts.",
  }
} as const

export const PromoHeroSection = () => {
  const { 
    email, 
    setEmail,
    emailError, 
    isConfirmed, 
    handleSubmit,
  } = useEmailSignup({ storageKey: LOCAL_STORAGE_CODE })

  const hasPromoCode = localStorage.getItem(LOCAL_STORAGE_CODE) === "true"

  const renderContent = () => {
    if (isConfirmed) {
      const { title, description } = PROMO_CONTENT.confirmed
      return (
        <div className="flex flex-col gap-6">
          <p className="text-4xl font-semibold">
            {title}
          </p>
          <p className="text-md font-light">
            {description}
          </p>
          <PromoCode code={NEW20} />
        </div>
      )
    }

    if (hasPromoCode) {
      const { title, description } = PROMO_CONTENT.returning
      return (
        <div className="flex flex-col gap-6">
          <p className="text-4xl font-semibold">
            {title}
          </p>
          <p className="text-md font-light">
            {description}
          </p>
          <div className="flex">
            <Button 
                variant="orange" 
                size="lg" 
                type="submit"
                className=" hover:bg-orange-500 transition-colors duration-500"
                onClick={() => {
                  window.location.href = "/search?facility=all"
                }}
              >
              Explore
            </Button>
          </div>
        </div>
      )
    }



    const { title, description, buttonText, emailPlaceholder } = PROMO_CONTENT.new
    return (
      <>
        <div className="flex flex-col gap-6">
          <p className="text-4xl font-semibold">
            {title}
          </p>
          <p className="text-md font-light">
            {description}
          </p>
        </div>
        <form 
          className="flex flex-col gap-2" 
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-1 w-full">
            <div className="flex flex-row gap-2">
              <input 
                type="email" 
                placeholder={emailPlaceholder} 
                className={`w-full p-2 rounded-md text-primary flex-2 ${
                  emailError ? 'border-2 border-red-500' : ''
                }`}
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                aria-invalid={!!emailError}
                aria-describedby={emailError ? "email-error" : undefined}
              />
              <Button 
                variant="orange" 
                size="lg" 
                type="submit"
                className="w-full lg:w-[unset] hover:bg-orange-500 transition-colors duration-500 flex-1"
              >
                {buttonText}
              </Button>
            </div>
            {emailError && (
              <span 
                id="email-error" 
                className="text-red-300 text-sm"
                role="alert"
              >
                {emailError}
              </span>
            )}
          </div>
        </form>
      </>
    )
  }

  return (
    <section className="p-12 h-fit w-full lg:w-[90%] bg-primary lg:rounded text-white mx-auto lg:mt-20 relative shadow-2xl">
      <div className="flex flex-wrap items-center gap-8">
        <div className="relative w-full lg:flex-1">
          <div className="h-[250px] w-100 lg:h-[350px] lg:w-[400px] rounded shrink-0 relative lg:absolute lg:-translate-y-1/2 right-0 shadow-xl">
            <picture>
              <source media="(min-width: 1024px)" srcSet={ctaImage} />
              <img
                src={ctaImageMobile}
                alt="Ski resort scenery"
                className="w-full h-full object-cover rounded-xl lg:rounded"
              />
            </picture>
          </div>
        </div>
        <div className="flex flex-col gap-10 lg:w-1/2">
          {renderContent()}
        </div>
      </div>
    </section>
  )
}