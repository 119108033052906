import { TypographyH3 } from "@/components/ui/typography/h3"
import { GetNewText, NoneAvailableText } from "./const"
import { AccordionFormClasses } from "../../const"
import { CardRadioGroup } from "@/components/custom/Forms/CardRadioGroup"
import { useReservationForm } from "../../context/ReservationFormContext"
import { useState } from "react"
import { LoginDialog } from "@/components/custom/LoginDialog/LoginDialog"

export const LoggedOutPacksForm = () => {
  const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false)
  const { form, control, facilityParkingPacks } = useReservationForm()

  if (!facilityParkingPacks || facilityParkingPacks.length === 0) {
    return (
      <section className={AccordionFormClasses}>
        <TypographyH3 className="text-muted-foreground text-md">
          {NoneAvailableText}
        </TypographyH3>
      </section>
    )
  }

  return (
    <section className={AccordionFormClasses}>
      <TypographyH3 className="text-md">
        {GetNewText}
      </TypographyH3>
      <div className="flex flex-col gap-1">
        <CardRadioGroup
          control={control}
          name="usingPack"
          label="Payment Packs"
          viewLabel={false}
          onSelectionChange={() => {
            setLoginDialogIsOpen(true)
          }}
          items={
            facilityParkingPacks?.map((pack) => ({
              label: pack?.name,
              value: pack?.id,
              price: `$${pack?.price}`,
              description: pack?.description,
              iconName: pack?.iconName,
              hasUnlimitedParkings: pack?.allowUnlimitedParkings,
              disabled: false
            })) ?? []
          }
        />
        <LoginDialog
          open={loginDialogIsOpen}
          onOpenChange={() => {
            setLoginDialogIsOpen(false)
            form?.setValue("usingPack", undefined)
          }}
          onSuccess={() => {}}
        />
      </div>
    </section>
  )
}
