import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { DateRange } from 'react-day-picker'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"

interface CalendarProps {
  onChange: (dates: { checkinDate: Date, checkoutDate?: Date }) => void,
  start?: Date | string,
  end?: Date | string,
  disabled?: ((date: Date) => boolean) | undefined,
  showPopoverBorder?: boolean
}

export function CalendarPopover({ start, end, onChange, disabled, showPopoverBorder = false }: CalendarProps) {
  const [date, setDate] = useState<DateRange | undefined>({
    from: start ? new Date(start) : undefined,
    to: end ? new Date(end) : undefined,
  })
  const [isRangeMode, setIsRangeMode] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setDate({
      from: start ? new Date(start) : undefined,
      to: end ? new Date(end) : undefined,
    })

    if (start && end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      if (startDate.getTime() !== endDate.getTime()) {
        setIsRangeMode(true)
      }
    }
  }, [start, end])

  const handleOnSelect = (selectedDate: DateRange | Date | undefined) => {
    if (isRangeMode && selectedDate && 'from' in selectedDate) {
      setDate(selectedDate);
      if (selectedDate.from && selectedDate.to) {
        onChange({
          checkinDate: selectedDate.from,
          checkoutDate: selectedDate.to,
        });
      }
    } else if (!isRangeMode && selectedDate instanceof Date) {
      setDate({ from: selectedDate, to: selectedDate });
      onChange({ checkinDate: selectedDate, checkoutDate: selectedDate });
    }
  };

  const toggleMode = (type: string) => {
    type === "single"
      ? setIsRangeMode(false)
      : setIsRangeMode(true)
    setDate(undefined)
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-full justify-between text-left font-normal text-base',
            showPopoverBorder ? 'border' : 'border-none'
          )}
          onClick={() => setIsOpen(true)}
        >
          {date?.from ? (
            isRangeMode && date.to ? (
              <>
                {format(date.from, 'LLL dd')} - {format(date.to, 'LLL dd')}
              </>
            ) : (
              format(date.from, 'EEE, LLL dd')
            )
          ) : (
            <span className="text-base">Select date{isRangeMode ? 's' : ''}</span>
          )}
          <CalendarIcon className="ml-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        align="start"
      >
        <div className="p-3 pb-0 flex justify-between items-center">
          <Tabs defaultValue={isRangeMode ? "range" : "single"} className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="single" onClick={() => toggleMode('single')}>Single Day</TabsTrigger>
            <TabsTrigger value="range" onClick={() => toggleMode('range')}>Range</TabsTrigger>
          </TabsList>
          <TabsContent value="single">
            <span className="text-xs">Select a single day to park.</span>
          </TabsContent>
          <TabsContent value="range">
            <span className="text-xs">
              Select a range of days to park.
            </span>
          </TabsContent>
        </Tabs>
        </div>
        <Calendar
          mode={isRangeMode ? "range" : "single"}
          defaultMonth={date?.from}
          selected={isRangeMode ? date : date?.from}
          onSelect={(selectedDate: Date | DateRange | undefined) => {
            if (isRangeMode) {
              handleOnSelect(selectedDate as DateRange | undefined);
            } else {
              handleOnSelect(selectedDate as Date | undefined);
            }
          }}
          disabled={disabled}
          numberOfMonths={1}
        />
      </PopoverContent>
    </Popover>
  )
}
