import { Link, useParams } from "react-router-dom"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { cn, getFormattedDateTime } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import TypographyH1 from "@/components/ui/typography/h1"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import { getOrderById } from "@/api/get"
import Loading from "@/components/custom/Loading"
import { Separator } from "@/components/ui/separator"
import { Receipt } from "lucide-react"
import SupportDialog from "@/components/custom/SupportDialog"
import { buttonVariants } from "@/components/ui/button"
import { CircleHelp, Home } from "lucide-react"
import { DialogTrigger } from "@/components/ui/dialog"
import Fallback from "@/components/custom/Fallback"
import { LabelledInfoHover } from "@/components/custom/Hoverable/LabelledInfoHover"
import { DiscountPrice } from "@/components/custom/DiscountPrice/DiscountPrice"

const PageLabel = "reservation_confirmation"

const ReservationSuccessPage = () => {
  const { user } = useAuth()
  const { id } = useParams()
  const { data, isPending, error } = useQuery({
    queryKey: [`order-${id}`],
    queryFn: () => getOrderById(id)
  })

  if (isPending) return <Loading />

  if (error) return <Fallback />

  const { amount, finalAmount, code, receipt, codeType } = data

  const {
    checkinDatetime,
    checkoutDatetime,
    facility: {
      name: facilityName,
      address: { address1, address2, city, state, zipCode }
    },
    vehicle = { make: "", model: "", color: "", licenseNumber: "" },
    parker: { email },
    spot,
    lot,
    images,
    paidAmenities,
    pack,
  } = data.reservations[0]

  const parkingPack = pack?.parkingPack || null;

  const [formattedCheckinTime, formattedCheckinDate] = getFormattedDateTime(checkinDatetime)
  const [formattedCheckoutTime, formattedCheckoutDate] = getFormattedDateTime(checkoutDatetime)

  let name = spot ? spot.name : lot.name

  const dialogTrigger = (
    <DialogTrigger
      className={cn(
        buttonVariants({ variant: "secondary" }),
        "flex items-center gap-2 rounded-lg px-3 py-2 transition-all hover:text-primary"
      )}>
      <CircleHelp className="h-4 w-4" />
      Support
    </DialogTrigger>
  )

  return (
    <div className="p-4 lg:px-10 lg:py-6">
      <TypographyH1>Reservation Confirmed</TypographyH1>
      <Card className="mt-4">
        <CardHeader>
          <CardTitle>Reservation for {email}</CardTitle>
          <CardDescription className="pt-2">
            {receipt && (
              <div className="flex items-center gap-3">
                <Button variant="secondary">
                  <Receipt className="inline h-6 w-6" />
                  <a
                    className="text-accent-foreground text-lg ml-2"
                    href={receipt}
                    target="_blank"
                    rel="noopener noreferrer">
                    View Receipt
                  </a>
                </Button>
                <LabelledInfoHover
                  labelKey={`${PageLabel}.receipt_url`}
                  displayKeyOnError
                />
              </div>
            )}
          </CardDescription>
        </CardHeader>
        <Separator />
        <CardContent className="grid grid-cols-1 md:grid-cols-2 pt-4">
          <div className="order-2 md:col-span-2 md:order-1">
            <div className="flex flex-col md:flex-row grid-cols-1 md:grid-cols-2">
              <div className="order-1 md:order-0 flex flex-col">
                <p className="font-semibold">{name}</p>
                <p className="font-semibold">{facilityName}</p>
                <address className="font-medium not-italic tracking-wide">
                  <p>{address1}</p>
                  <p>{address2}</p>
                  <p>
                    {city}, {state} {zipCode}
                  </p>
                </address>
                <div className="flex gap-6 py-4">
                  <div>
                    <p className="mb-1 font-semibold">Enter At:</p>
                    <p>{formattedCheckinTime}</p>
                    <p>{formattedCheckinDate}</p>
                  </div>
                  <div>
                    <p className="mb-1 font-semibold">Leave By:</p>
                    <p>{formattedCheckoutTime}</p>
                    <p>{formattedCheckoutDate}</p>
                  </div>
                </div>
              </div>
              <div className="order-0 md:order-1 mb-4 ml-auto">
                <img
                  alt={images[0]?.name}
                  className="aspect-square rounded-md object-cover w-full md:max-h-[200px]"
                  src={images[0]?.file}
                />
              </div>
            </div>
            {
              lot?.parkerInstructions && (
                <div className="bg-slate-100 p-4 rounded">
                  <p className="font-semibold">Parking Instructions:</p>
                  <p>{lot.parkerInstructions}</p>
                </div>
              )
            }
            {
              spot?.parkerInstructions && (
                <div className="bg-slate-100 p-4 rounded">
                  <p className="font-semibold">Parking Instructions:</p>
                  <p>{spot.parkerInstructions}</p>
                </div>
              )
            }
            {
              paidAmenities.length > 0 && paidAmenities.map((amenity, i) => (
                <div className="flex flex-col md:flex-row gap-6 py-4" key={`amenity-${i}`}>
                  {
                    amenity.file && (
                      <img
                        className="max-h-[200px] md:max-h-[200px] md:max-w-[200px] w-full object-cover rounded-xl"
                        src={amenity.file}
                      />
                    )
                  }
                  <div className="flex flex-col">
                    <p className="font-semibold">{amenity.name}</p>
                    <p className>{amenity.description}</p>
                  </div>
                </div>
              ))
            }
            {
              parkingPack && (
                <div className="bg-slate-100 rounded p-4">
                  <p className="font-semibold">Parking Pack:</p>
                  <p>{parkingPack?.name}</p>
                </div>
              )
            }
            {/* Pricing */}
            <div className="flex flex-col py-4 my-2">
              <p className="font-semibold">Price:</p>
              <p className="text-secondary-foreground">
                {code ?
                  <DiscountPrice
                    code={code}
                    original={amount}
                    reduced={finalAmount}
                    isFreeVoucher={codeType === "FREEVOUCHER"}
                  />
                : `$${finalAmount}`}
              </p>
              {code && (
                <div>
                  <p className="font-semibold pt-2">Promo code:</p>
                  <p className="bg-primary text-white py-1 px-2 rounded-3xl text-xs inline">
                    {code}
                  </p>
                </div>
              )}
            </div>
            {
              vehicle && (
                <div className="flex gap-6">
                  <div>
                    <p className="font-semibold">Vehicle: </p>
                    <p>
                      <span className="capitalize">{vehicle.color.toLowerCase()}</span> {vehicle.make}{" "}
                      {vehicle.model}
                    </p>
                  </div>
                  <div>
                    <p className="font-semibold">Plate: </p>
                    <p>
                      {vehicle.licenseNumber}
                    </p>
                  </div>
                </div>
              )
            }
            {
              !vehicle && (
                <div>
                  <p className="font-semibold">Vehicle: </p>
                  <p>
                    <span className="uppercase">Rental</span>
                  </p>
                </div>
              )
            }
          </div>
          {/* <div className="order-1 mb-4 md:order-2">
            <img
              alt={images[0]?.name}
              className="aspect-square rounded-md object-cover max-h-[50vh]"
              src={images[0]?.file}
            />
          </div> */}
        </CardContent>
        <CardFooter className="flex flex-col justify-center items-stretch gap-2 md:grid md:grid-cols-2">
          {user ?
            <Link to="/parker-dashboard">
              <Button className="w-full bg-primary text-white flex gap-2" variant="secondary">
                <Home className="h-4 w-4" />
                Go to Dashboard
              </Button>
            </Link>
          : <Link to="/signup">
              <Button className="w-full" variant="secondary">
                Create Account
              </Button>
            </Link>
          }
          <SupportDialog dialogTrigger={dialogTrigger} />
        </CardFooter>
      </Card>
    </div>
  )
}

export default ReservationSuccessPage
