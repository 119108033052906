import { cn } from "@/lib/utils"
import type { ReactNode } from "react"

interface TypographyH3Props {
  children: ReactNode
  className?: string
}

export function TypographyH3({ children, className }: TypographyH3Props) {
  return (
    <h3
      className={cn(
        "scroll-m-20 text-2xl font-semibold tracking-wide",
        className
      )}>
      {children}
    </h3>
  )
}
