
export const formatDateForURL = (date) => {
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
  const dd = String(date.getDate()).padStart(2, '0');
  const yyyy = date.getFullYear();

  const formattedDate = `${mm}/${dd}/${yyyy}`;

  // URL-encode the date string
  return encodeURIComponent(formattedDate);
}

export const searchAll = () => `/search?facility=all&checkinDate=${formatDateForURL(new Date())}&checkoutDate=${formatDateForURL(new Date())}`
