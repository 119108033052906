import { z } from "zod"
import {
  ParkerVehicleSchema,
  VehicleForm
} from "./sections/ParkerVehicleAccordion/const"
import { AddOnsSchema } from "./sections/AddOnsAccordion/const"
import { PacksSchema } from "./sections/PacksAccordion/const"
import { Reservation } from "@/api/types/internal/Reservation"
import { ReservationFormSection } from "./context/const"

export interface ReservationSectionProps
  extends Omit<ReservationFormSection, "Render"> {
  activeId?: string
  isLastItem?: boolean
}

export const AccordionFormClasses = "py-6 grid gap-3"

export const ReservationSchema = ParkerVehicleSchema.merge(
  z.object({
    addOns: AddOnsSchema
  })
).merge(PacksSchema)

export type ReservationForm = z.infer<typeof ReservationSchema>

export const defaultValues = (
  init?: Partial<ReservationForm>
): ReservationForm => ({
  addOns: init?.addOns ?? {},
  email: init?.email ?? "",
  purchasingPack: init?.purchasingPack ?? "",
  usingPurchasedPack: init?.usingPurchasedPack ?? false,
  usingPack: init?.usingPack ?? "",
  phone: init?.phone ?? "",
  isRental: init?.isRental ?? false,
  vehicle:
    init?.vehicle ??
    ({
      isEv: false,
      licenseNumber: "",
      make: "",
      model: "",
      color: ""
    } as VehicleForm)
})

export const getThumbnail = (reservation?: Reservation) => {
  if (reservation?.images && reservation?.images.length > 0) {
    const thumbnails = reservation.images.filter((image) => image.isThumbnail)
    return (
      thumbnails.length > 0 ?
        thumbnails[0]
      : reservation.images[0]) as any
  }
}
