import { useState } from "react"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAuth } from "@/context/AuthContext"
import { userRoles } from "@/constants/constants"
import { requiredEmail, requiredString } from "@/constants/formSchema"
import { searchAll } from "@/lib/formatDateForUrl"

const loginFormSchema = z.object({
  email: requiredEmail,
  password: requiredString
})

const LoginForm = () => {
  const [error, setError] = useState(null)
  const { login } = useAuth()
  const navigate = useNavigate()
  const form = useForm({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  })

  const handleSubmit = async (formData) => {
    try {
      const userRole = await login(formData)
      userRole === userRoles.PARKER
        ? (window.location.href = searchAll())
        : navigate("/owner-dashboard")
    } catch (error) {
      console.log('HERE', error)
      if (error.response?.status === 401) {
        setError({ ...error, message: "Incorrect email or password. Please try again." })
      } else {
        setError({ ...error, message: "An error occurred. Please try again." })
      }
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Login</CardTitle>
            <CardDescription>
              Enter your email below to login to your account
            </CardDescription>
          </CardHeader>
          <CardContent>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Your email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Your password"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid gap-4">
              {error && error.message ?
                <div>
                  <p className="text-red-500 text-sm">{error.message}</p>
                </div>
                : null}
              <Link
                href="#"
                to="/reset-password"
                className="inline-block text-sm underline text-secondary-foreground">
                Forgot your password?
              </Link>
              <Button type="submit" className="w-full">
                Login
              </Button>
            </div>
            <div className="mt-4 text-center text-sm">
              Don&apos;t have an account?{" "}
              <Link to="/signup" className="underline">
                Sign up
              </Link>
            </div>
          </CardContent>
        </Card>
      </form>
    </Form>
  )
}

export default LoginForm
