import { useEffect, useMemo } from "react"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { format, isBefore, isAfter } from "date-fns"

const MONTHS_AVAILABLE_COUNT = 6
export const ALL_AVAILABLE_FACILITIES = "all"

const formSchema = z.object({
  facility: z.string().trim(),
  checkinDate: z.date(),
  checkoutDate: z.date().optional()
})

const defaultValues = {
  facility: ALL_AVAILABLE_FACILITIES,
  checkinDate: new Date(),
  checkoutDate: new Date()
}

export const useParkingSearchForm = (searchQuery, facilities) => {
  const { reset, ...form } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues
  })

  const facilityOptions = useMemo(
    () => [
      { id: ALL_AVAILABLE_FACILITIES, name: "Vail & Beaver Creek" },
      ...facilities
    ],
    [facilities]
  )

  useEffect(() => {
    if (!searchQuery || Object.keys(searchQuery).length === 0) return

    const { facility, checkinDate, checkoutDate } = searchQuery
    if (!facility && !checkinDate) return

    reset({
      ...defaultValues,
      ...(facility ? { facility } : {}),
      ...(checkinDate ? { checkinDate: new Date(checkinDate) } : {}),
      ...(checkoutDate ? { checkoutDate: new Date(checkoutDate) } : {})
    })
  }, [reset, searchQuery])

  const disableSelectionAvailability = (date) => {
    const today = format(new Date(), "yyyy-MM-dd")
    const formattedDateArg = format(date, "yyyy-MM-dd")
    const availabilityEndDate = format(
      new Date().setMonth(new Date().getMonth() + MONTHS_AVAILABLE_COUNT),
      "yyyy-MM-dd"
    )
    return (
      isBefore(formattedDateArg, today) ||
      isAfter(formattedDateArg, availabilityEndDate)
    )
  }

  return {
    form,
    facilityOptions,
    disableSelectionAvailability
  }
}
