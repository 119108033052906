import { TypographyH2 } from "@/components/ui/typography/h2"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { TypographyP } from "@/components/ui/typography/p"
import { useQuery } from "@tanstack/react-query"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import { Link } from "react-router-dom"
import * as contentful from "contentful"
import { MetaHead } from "@/components/custom/Meta/Head"
import type { TypeBlogPageSkeleton } from './types';
import { formatDateTime } from './utils';


const client = contentful.createClient({
  space: import.meta.env.VITE_CONTENTFUL_SPACE_ID,
  accessToken: import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN,
  environment: import.meta.env.VITE_CONTENTFUL_ENVIRONMENT,
})

const BlogPage = () => {
  const { data, isPending, error } = useQuery({
    queryKey: ["posts"],
    queryFn: async () => {
      const entries = await client.getEntries<TypeBlogPageSkeleton>({
        content_type: "blogPage",
      });
      
      // Sort entries by backDate or createdAt
      entries.items.sort((a, b) => {
        const dateA = a.fields?.backDate || a.sys.createdAt;
        const dateB = b.fields?.backDate || b.sys.createdAt;
        return new Date(dateB).getTime() - new Date(dateA).getTime();
      });
      
      return entries;
    },
  })

  if (isPending) return <Loading />

  if (error) return <Fallback />

  
  
  return (
    <>
      <MetaHead
        title="News and Happenings | Spotsurfer"
        description="Welcome to Spotsurfer's News Page, your gateway to the latest updates and happenings in the world of seamless parking. Explore the pulse of our innovative solutions, and stay informed for a stress-free parking experience."
        url="https://spotsurfer.com/blog"
      />
      <div className="m-auto px-4 mb-40 text-left h-fit max-w-screen-lg">
        <TypographyH2 className="text-center my-4 pt-10">
          News and Happenings
        </TypographyH2>
        <TypographyP className="mb-4 lg:mb-20 text-center">
          Welcome to Spotsurfer&apos;s News Page, your gateway to the latest
          updates and happenings in the world of seamless parking. Explore the
          pulse of our innovative solutions, and stay informed for a stress-free
          parking experience
        </TypographyP>
        <div className="flex flex-col md:flex-row md:flex-wrap gap-4 lg:gap-8">
          {data.items.filter((post: any) => post.sys.contentType.sys.id === "blogPage").map(post => (
            <div key={post.sys.id} className="w-full md:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1.5rem)]">
              <Link to={`/blog/${post.fields?.slug}`}>
                <img
                  alt={'skier'}
                  className="aspect-square rounded-md object-cover m-auto lg:h-52 h-32 w-full my-6"
                  // @ts-ignore
                  src={post.fields.image?.fields.file.url}
                />
                <TypographyH3>{post.fields?.title}</TypographyH3>
                
                 <p className="leading-7 mt-2 text-gray-800 font-light text-sm">
                  {formatDateTime(post.fields?.backDate ? post.fields.backDate : post.sys.createdAt)}
                </p>
                
                <p className="leading-7 mt-4 line-clamp-3">
                  {post.fields?.body?.content.find((content: any) => {
                    return content.nodeType === 'paragraph'
                    // @ts-ignore
                  })?.content[0].value}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default BlogPage
