import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { getFormattedDateTime } from "@/lib/utils"
import { Separator } from "@/components/ui/separator"
import { Receipt } from "lucide-react"

const ReservationDetailsCard = ({ data }) => {
  const {
    checkinDatetime,
    checkoutDatetime,
    order: { amount, receipt },
    facility: {
      name: facilityName,
      address: { address1, address2, city, state, zipCode }
    },
    vehicle = { make: "", model: "", color: "", licenseNumber: "", isEv: false, registeredState: "", type: "" },
    spot,
    lot
  } = data

  let name = spot ? spot.name : lot.name

  const [formattedCheckinTime, formattedCheckinDate] =
    getFormattedDateTime(checkinDatetime)
  const [formattedCheckoutTime, formattedCheckoutDate] =
    getFormattedDateTime(checkoutDatetime)

  return (
    <Card className="w-full">
      <CardHeader className="pb-3">
        <CardTitle>Reservation Details for {name}</CardTitle>
        <CardDescription className="pt-2">
          {receipt && (
            <Button variant="secondary">
              <Receipt className="inline h-4 w-4" />
              <a className="text-accent-foreground text-md ml-2" href={receipt}>
                View Receipt
              </a>
            </Button>
          )}
        </CardDescription>
      </CardHeader>
      <CardContent className="pt-2">
        <div>
          <div className="lg:grid grid-cols-2">
            <div>
              <p>Address:</p>
              <address className="not-italic tracking-wide text-sm text-secondary-foreground font-semibold">
                <p>{facilityName}</p>
                <p>{address1}</p>
                <p>{address2}</p>
                <p>
                  {city}, {state} {zipCode}
                </p>
              </address>
            </div>
            <div className="mt-2">
              <p>Price:</p>
              <p className="text-sm text-secondary-foreground font-semibold">
                ${amount}
              </p>
            </div>
          </div>
          <Separator className="my-4" />
          <div className="grid grid-cols-2 text-sm">
            <div>
              <p className="mb-1">Enter At:</p>
              <p className="text-sm font-semibold">{formattedCheckinTime}</p>
              <p className="text-sm font-semibold">{formattedCheckinDate}</p>
            </div>
            <div>
              <p className="mb-1">Leave By:</p>
              <p className="text-sm font-semibold">{formattedCheckoutTime}</p>
              <p className="text-sm font-semibold">{formattedCheckoutDate}</p>
            </div>
          </div>
        </div>
          <div>
            <p className="font-bold mt-2">Vehicle</p>
            <div className="grid grid-cols-2 gap-y">
            {
              vehicle ? (
                <>
                  <div>
                    <p>Make/Model: </p>
                    <p className="text-secondary-foreground text-sm font-semibold">
                      {vehicle.make} {vehicle.model}
                    </p>
                  </div>
                  <div>
                    <p>Color: </p>
                    <p className="text-secondary-foreground text-sm font-semibold">
                      <span className="capitalize">{vehicle.color.toLowerCase()}</span>
                    </p>
                  </div>
                  <div>
                    <p>Plate: </p>
                    <p className="text-secondary-foreground text-sm font-semibold">
                      {vehicle.licenseNumber}
                    </p>
                  </div>
                  <div>
                    <p>Registed State: </p>
                    <p className="text-secondary-foreground text-sm font-semibold">
                      {vehicle.registeredState}
                    </p>
                  </div>
                  <div>
                    <p>Type: </p>
                    <p className="text-secondary-foreground text-sm font-semibold">
                      {vehicle.type}
                    </p>
                  </div>
                  <div>
                    <p>Is Electric Vehicle: </p>
                    <p className="text-secondary-foreground text-sm font-semibold">
                      {vehicle.isEv ? "Yes" : "No"}
                    </p>
                  </div>
                </>
              ) : (
                <p className="inline-flex rounded uppercase">Rental</p>
              )
            }
            </div>
          </div>
      </CardContent>
    </Card>
  )
}

export default ReservationDetailsCard
