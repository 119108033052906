import { z } from "zod"
import { AddOnsSchema } from "../sections/AddOnsAccordion/const"
import {
  ParkerVehicleSchema,
  VehicleForm
} from "../sections/ParkerVehicleAccordion/const"
import { PacksSchema } from "../sections/PacksAccordion/const"
import { Reservation } from "@/api/types/internal/Reservation"
import { CodeSchema } from "../sections/DiscountCodeAccordion/const"

export const ExtraReservationFields = z.object({
  checkinDate: z.string().refine((str) => /\d\d\/\d\d\/\d\d\d\d/.test(str)),
  checkoutDate: z.string().refine((str) => /\d\d\/\d\d\/\d\d\d\d/.test(str)),
  payViaCredits: z.coerce.boolean().optional().or(z.literal("")),
})

export const ReservationSchema = ParkerVehicleSchema.merge(CodeSchema)
  .merge(
    z.object({
      addOns: AddOnsSchema
    })
  )
  .merge(PacksSchema)
  .merge(ExtraReservationFields)
  .superRefine((data, ctx) => {
    if (!data.isRental && data.vehicle === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please select a vehicle or mark as rental",
        path: ["vehicle"]
      })
    }
  })

export type ReservationForm = z.infer<typeof ReservationSchema>

export interface ReservationLock {
  id: string
  expires: number
  redirectHandler: () => void
  error?: any
}

const validParkingLocationType = (data: any) => {
  const valid = ("parkingLocationType" in data)
  const spotOrLot = valid && data.parkingLocationType === "SPOT" || data.parkingLocationType === "LOT"

  return valid && spotOrLot
}

const facilityIdIsValid = (data: any) => {
  return "facility" in data && data.facility.id
}

export const routingStateIsValid = (
  data: any,
  searchQuery: any
): data is Reservation => {
  if (
    validParkingLocationType(data) &&
    facilityIdIsValid(data) &&
    searchQuery.checkinDate &&
    z
      .string()
      .refine((str) => /\d\d\/\d\d\/\d\d\d\d/.test(str))
      .safeParse(searchQuery.checkinDate).success
  ) {
    return true
  } else {
    return false
  }
}

export const defaultValues = (
  init?: Partial<ReservationForm>
): ReservationForm => ({
  addOns: init?.addOns ?? {},
  email: init?.email ?? "",
  purchasingPack: init?.purchasingPack ?? "",
  usingPurchasedPack: init?.usingPurchasedPack ?? false,
  usingPack: init?.usingPack ?? "",
  phone: init?.phone ?? "",
  checkinDate: init?.checkinDate ?? "",
  checkoutDate: init?.checkoutDate ?? "",
  payViaCredits: init?.payViaCredits ?? false,
  vehicle:
    init?.vehicle ??
    ({
      isEv: false,
      licenseNumber: "",
      make: "",
      model: "",
      color: ""
    } as VehicleForm),
  isRental: init?.isRental ?? false
})
