import TypographyH1 from "@/components/ui/typography/h1"
import Icon from "@/components/custom/Icon"
import { Badge } from "@/components/ui/badge"
import { Tag } from "@/api/types/internal/Tag"
import { Reservation } from "@/api/types/internal/Reservation"

import { useReservationForm } from "../context/ReservationFormContext"
import { format } from "date-fns"

interface HeaderProps {
  tags: Tag[];
}

const badges = (tags: Tag[]) => {
  if (tags?.length === 0) return null
  return (
    <>
      {tags?.map((tag: Tag, i: number) => (
        <Badge variant="" key={`${tag.name}-${i}`} className="capitalize mr-1">{tag.name}</Badge>
      ))}
    </>
  )
}

export const Header = ({ tags }: HeaderProps) => {
  const { reservation, form } = useReservationForm()

  const facilityAddress = (reservation: Reservation) => {
    return `
      ${reservation?.facility?.address?.address1},
      ${reservation?.facility?.address?.city},
      ${reservation?.facility?.address.state}
      ${reservation?.facility.address.zipCode}`
  }

  return (
    <div className="md:flex md:flex-col">
      <div className="flex flex-col md:flex-row gap-3 relative">
        <div
          role="contentinfo"
          aria-label="Reservation Title"
          className="flex gap-6 justify-between items-center">
          <div className="flex flex-col px-4 pt-4 pb-2 md:py-0">
            <TypographyH1 className="text-xl font-semibold">
              {reservation?.parkingLocationType === "LOT" ?
                reservation?.lot?.name
              : reservation?.parkingLocationType === "SPOT" &&
                reservation.spot?.name
              }
            </TypographyH1>
            {reservation?.facility?.name && (
              <div className="flex flex-col">
                <div className="flex items-center">
                  <Icon name="map-pin" size={18} className="stroke-2 mr-1" />
                  <p
                    role="contentinfo"
                    aria-label="Facility Name"
                    className="md:text-lg">
                    {facilityAddress(reservation)}
                  </p>
                </div>
                <div className="flex items-center">
                  <Icon name="calendar" size={18} className="stroke-2 mr-1" />
                  <span className="relative top-[1px]">{format(form?.watch("checkinDate") ?? "", "MMM d y")}</span>
                  {
                    form?.watch("checkinDate") !== form?.watch("checkoutDate") && (
                      <span className="relative top-[1px]">
                        <span className="px-2">-</span>
                        {format(form?.watch("checkoutDate") ?? "", "MMM d y")}
                      </span>
                    )
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="px-4 md:py-2 w-full">
        {badges(tags)}
      </div>
    </div>
  )
}
