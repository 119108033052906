// src/hooks/useEmailSignup.ts
import { useState, useEffect } from "react"

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

interface ValidationResult {
  isValid: boolean
  error: string
}

interface FormEvent extends React.FormEvent<HTMLFormElement> { }

interface useEmailSignupProps {
  storageKey?: string;
}

export const useEmailSignup = ({ storageKey }: useEmailSignupProps = {}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!storageKey) return
    const hasVisited = localStorage.getItem(storageKey)

    if (hasVisited === "true") {
      setIsOpen(false)
    } else {
      setTimeout(() => {
        setIsOpen(true)
      }, 3000)
    }
  }, [])

  const validateEmail = (email: string): ValidationResult => {
    if (!email) {
      return { isValid: false, error: "Email is required" }
    }
    if (!EMAIL_REGEX.test(email)) {
      return { isValid: false, error: "Please enter a valid email address" }
    }
    return { isValid: true, error: "" }
  }

  const setLocalStorageCode = (localStorageCode: string, bool: boolean) => {
    localStorage.setItem(localStorageCode, bool.toString())
  }

  const handleClose = () => {
    setIsOpen(false)
    if (storageKey) {
      setLocalStorageCode(storageKey, true)
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const { isValid, error } = validateEmail(email)
    setEmailError(error)

    if (!isValid) return false

    setIsLoading(true)
    try {
      const response = await fetch('https://formsubmit.co/ajax/af67127e26fb2a9726faadf0a1315e28', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email,
          source: 'Email Signup Form'
        })
      })

      if (!response.ok) {
        throw new Error('Submission failed')
      }

      setIsConfirmed(true)
      setEmail("")
      setEmailError("")
      if (storageKey) {
        setLocalStorageCode(storageKey, true)
      }
      return true
    } catch (error) {
      setEmailError("Failed to sign up. Please try again.")
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isOpen,
    email,
    setEmail,
    emailError,
    isConfirmed,
    isLoading,
    handleClose,
    handleSubmit,
  }
}