import { v4 as uuidv4 } from "uuid"
import { Dot } from "lucide-react"

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/components/ui/carousel"

const FacilityDetailsCard = ({ data }) => {
  const {
    facility: {
      name,
      description,
      amenities,
      images,
    }
  } = data

  const carouselImages = images.map(({ file, name }) => (
    <CarouselItem className="max-w-80" key={uuidv4()}>
      <img
        alt={name}
        src={file}
        className="aspect-square rounded-md object-cover"
      />
    </CarouselItem>
  ))

  const amenityItems = amenities.map((amenity) => {
    return (
      <p
        key={uuidv4()}
        className="text-sm">
        <Dot className="w-4 h-4 inline" />
        {amenity.name}
      </p>
    )
  })

  const carousel = () => {
    if (carouselImages.length > 1) {
      return (
        <Carousel className="w-2/3 m-auto">
          <CarouselContent>{carouselImages}</CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      )
    } else {
      return (
        <div>
          <img
            alt={images[0].name}
            src={images[0].file}
            className="aspect-square rounded-md object-cover h-56 w-full m-auto"
          />
        </div>
      )
    }
  }

  return (
    <Card className="mb-6 lg:mb-0 lg:mr-6 max-w-3xl">
      <CardHeader>
        <CardTitle>Facility Details for {name}</CardTitle>
      </CardHeader>
      <CardContent>
        {carouselImages.length > 0 && carousel()}
        <CardDescription className="pt-2">{description}</CardDescription>
        <div className="lg:grid grid-cols-2 gap-4">
          {amenityItems.length > 0 && (
            <div>
              <p className="font-semibold mt-2">Amenities:</p>
              {amenityItems}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default FacilityDetailsCard
