import TypographyH1 from "@/components/ui/typography/h1"
import { Link } from "react-router-dom"

export const MarketPlaceFailurePage = () => {
  return (
    <div className="p-6 max-w-5xl mx-auto lg:py-10">
      <div className="flex flex-col gap-6">
        <TypographyH1 className="text-md">An error has occured.</TypographyH1>
        <p>We apologize for the inconvenience.</p>
        <p>
          Please click{" "}
          <Link to="/market" className="underline">
            here
          </Link>{" "}
          to return to the Specials page.
        </p>
      </div>
    </div>
  )
}
