import { cn } from "@/lib/utils"
import type { ReactNode } from "react"

interface TypographyH1Props {
  children: ReactNode
  className?: string
}

const TypographyH1 = ({ children, className = "" }: TypographyH1Props) => {
  return (
    <h1
      className={cn(
        "scroll-m-20 text-3xl font-semibold tracking-wide lg:text-4xl mb-1",
        className
      )}>
      {children}
    </h1>
  )
}

export default TypographyH1
