// React imports
import { useState } from "react"
import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Calendar } from "@/components/ui/calendar"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Card } from "@/components/ui/card"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { TypographyP } from "@/components/ui/typography/p"
import { cn, getYearMonthDay, sortFacilities } from "@/lib/utils"
import { ALL_AVAILABLE_FACILITIES, useParkingSearchForm } from "@/hooks/useParkingSearchForm"
import { CalendarPopover } from "@/components/custom/CalendarPopover.tsx"
import Icon from "./Icon"

const MobileParkSearchForm = ({ searchQuery, handleSearchQuery, facilities, resetCarouselSelection }) => {
  const { form, facilityOptions, disableSelectionAvailability } = useParkingSearchForm(searchQuery, sortFacilities(facilities))
  const [isEditOpen, setIsEditOpen] = useState(false)

  // TODO: remove in the future
  // I am leaving this here in case something breaks
  // const triggerRef = useRef(null)
  // const [popoverWidth, setPopoverWidth] = useState(0)


  // const updatePopoverWidth = () => {
  //   if (triggerRef.current) {
  //     setPopoverWidth(triggerRef.current.offsetWidth)
  //   }
  // }

  // useEffect(() => {
  //   updatePopoverWidth()
  //   window.addEventListener('resize', updatePopoverWidth)
  //   return () => window.removeEventListener('resize', updatePopoverWidth)
  // }, [])

  const onSubmit = async ({ facility, checkinDate, checkoutDate }) => {
    const checkinDateFormatted = getYearMonthDay(checkinDate)
    const checkoutDateFormatted = getYearMonthDay(checkoutDate)

    const query = {
      facility,
      checkinDate: checkinDateFormatted,
      checkoutDate: checkoutDateFormatted
    }
    handleSearchQuery(query)
    resetCarouselSelection()
    setIsEditOpen(false)
  }

  const { facility = ALL_AVAILABLE_FACILITIES, checkinDate } = searchQuery
  const selectedfacilty = facilityOptions.find(({ id }) => id === facility)

  return (
    <Form {...form} >
      <form onSubmit={form.handleSubmit(onSubmit)} className="max-h-content flex m-2 gap-1" style={{ width: '100%' }}>
        <FormField
          control={form.control}
          name="facility"
          render={({ field }) => (
            <FormItem className="flex-1">
              <Select
                onValueChange={field.onChange}
                value={field.value}>
                <FormControl>
                  <SelectTrigger className="border-none rounded-l-full rounded-r-none">
                    <SelectValue placeholder="Select a parking facility" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {facilityOptions.map(({ id, name }) => (
                    <SelectItem value={id} key={id}>
                      {name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="dateRange"
          render={({ field }) => (
            <FormItem>
              <CalendarPopover
                onChange={(selection) => {
                  form.setValue("checkinDate", selection.checkinDate)
                  form.setValue("checkoutDate", selection.checkoutDate)
                }}
                start={form.getValues("checkinDate")}
                end={form.getValues("checkoutDate")}
                disabled={disableSelectionAvailability}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" variant="orange" className="rounded-l-none rounded-r-full hover:bg-accent-yellow">
          Search
        </Button>
      </form>
    </Form>
  )
}

export default MobileParkSearchForm
