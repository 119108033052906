import Home from "@/pages/Home"
import SearchPage from "@/pages/SearchPage"
import SignUp from "@/pages/SignUp"
import LoginPage from "@/pages/LoginPage"
import { CreateReservationPage } from "@/pages/ReservationFlowRedo/CreateReservationPage/CreateReservationPage"
import ReservationSuccessPage from "@/pages/ReservationSuccessPage"
import ReservationFailurePage from "@/pages/ReservationFailurePage"
import PublicLayout from "@/components/custom/PublicLayout"
import { SearchProvider } from "@/context/SearchContext"
import ResetPasswordPage from "@/pages/ResetPasswordPage"
import NewPasswordPage from "@/pages/NewPasswordPage"
import TermsPage from "@/pages/TermsPage"
import AboutPage from "@/pages/AboutPage"
import NotFound404Page from "@/pages/NotFound404Page"
import ContactPage from "@/pages/ContactPage"
import PartnershipsPage from "@/pages/PartnershipsPage"
import BlogPostPage from "@/pages/Blog/BlogPostPage"
import BlogPage from "@/pages/Blog/BlogPage"
import FAQsPage from "@/pages/FAQsPage"
import AccountDeletionRequest from "@/pages/AccountDeletionRequest"
import OwnerSignupPage from "@/pages/OwnerSignupPage"
import OwnerSignupConfirmationPage from "@/pages/OwnerSignupConfirmationPage"
import { Impersonate } from "@/pages/Impersonate"
import { RouteObject } from "react-router-dom"
import { SeasonPacks } from "./PublicRoutes/SeasonPacks/SeasonPacks"
import { ShopConfirmationPage } from "./PublicRoutes/SeasonPacks/Confirmation/ShopConfirmation"

export const PublicRoutes: RouteObject[] = [
  {
    index: true,
    path: "/",
    element: (
      <PublicLayout>
        <SearchProvider>
          <Home />
        </SearchProvider>
      </PublicLayout>
    ),
  },
  {
    path: "search",
    element: (
      <PublicLayout showFooter={false}>
        <SearchProvider>
          <SearchPage />
        </SearchProvider>
      </PublicLayout>
    )
  },
  {
    path: "terms-and-conditions",
    element: (
      <PublicLayout>
        <TermsPage />
      </PublicLayout>
    )
  },
  {
    path: "parking-packs",
    element: (
      <PublicLayout>
        <SeasonPacks />
      </PublicLayout>
    )
  },
  {
    path: "signup",
    element: (
      <PublicLayout>
        <SignUp />
      </PublicLayout>
    )
  },
  {
    path: "login",
    element: (
      <PublicLayout>
        <LoginPage />
      </PublicLayout>
    )
  },
  {
    path: "reservation/:id",
    element: (
      <PublicLayout>
        <CreateReservationPage />
      </PublicLayout>
    ),
    // @ts-ignore
    siteMapExclude: true,
  },
  {
    path: "/shop/confirmation/:orderId",
    element: (
      <PublicLayout>
        <ShopConfirmationPage />
      </PublicLayout>
    ),
    // @ts-ignore
    siteMapExclude: true,
  },
  {
    path: "orders/:id",
    element: (
      <PublicLayout>
        <ReservationSuccessPage />
      </PublicLayout>
    ),
    // @ts-ignore
    siteMapExclude: true,
  },
  {
    path: "reservation/failed",
    element: (
      <PublicLayout>
        <ReservationFailurePage />
      </PublicLayout>
    ),
    // @ts-ignore
    siteMapExclude: true,
  },
  {
    path: "reset-password",
    element: (
      <PublicLayout>
        <ResetPasswordPage />
      </PublicLayout>
    )
  },
  {
    path: "new-password",
    element: (
      <PublicLayout>
        <NewPasswordPage />
      </PublicLayout>
    )
  },
  {
    path: "owner-signup",
    element: (
      <PublicLayout>
        <OwnerSignupPage />
      </PublicLayout>
    )
  },
  {
    path: "owner-signup-confirmation",
    element: (
      <PublicLayout>
        <OwnerSignupConfirmationPage />
      </PublicLayout>
    ),
    // @ts-ignore
    siteMapExclude: true,
  },
  {
    path: "about",
    element: (
      <PublicLayout>
        <AboutPage />
      </PublicLayout>
    )
  },
  {
    path: "contact-us",
    element: (
      <PublicLayout>
        <ContactPage />
      </PublicLayout>
    )
  },
  {
    path: "community",
    element: (
      <PublicLayout>
        <PartnershipsPage />
      </PublicLayout>
    )
  },
  {
    path: "news-happenings",
    element: (
      <PublicLayout>
        <BlogPage />
      </PublicLayout>
    )
  },
  {
    path: "blog/:id",
    element: (
      <PublicLayout>
        <BlogPostPage />
      </PublicLayout>
    ),
    // @ts-ignore
    siteMapExclude: true,
  },
  {
    path: "FAQs",
    element: (
      <PublicLayout>
        <FAQsPage />
      </PublicLayout>
    )
  },
  {
    path: "account-deletion-request",
    element: (
      <PublicLayout>
        <AccountDeletionRequest />
      </PublicLayout>
    )
  },
  {
    path: "impersonate",
    element: (
      <PublicLayout>
        <Impersonate />
      </PublicLayout>
    ),
    // @ts-ignore
    siteMapExclude: true
  },
  {
    path: "*",
    element: (
      <PublicLayout>
        <NotFound404Page />
      </PublicLayout>
    ),
    // @ts-ignore
    siteMapExclude: true,
  }
]
