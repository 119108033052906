import { useEffect, useState } from "react"
import { Minus, Plus } from "lucide-react"
import TypographyH1 from "@/components/ui/typography/h1"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { LoginDialog } from "@/components/custom/LoginDialog/LoginDialog"
import { getProducts } from "@/api/get"
import { buyProduct } from "@/api/post"
import { Product } from "@/types/Product"
import { useAuth } from "@/context/AuthContext"

export const MarketPlacePage = () => {
  const [products, setProducts] = useState<Product[]>([])
  const [categories, setCategories] = useState<Product['category'][]>([])
  const [renderProducts, setRenderProducts] = useState(false)
  const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false)

  const { accessToken } = useAuth()

  const updateQuantity = (type: 'increment' | 'decrement', productToUpdate: Product) => {
    setProducts(products.map(product =>
      product.id === productToUpdate.id
        ? {
            ...product,
            quantity: type === 'increment'
              ? product.quantity + 1
              : Math.max(product.quantity - 1, 1)
          }
        : product
    ));
  }

  const purchase = async (id: string, quantity: number) => {
    if (!accessToken) {
      setLoginDialogIsOpen(true)
      return
    }

    const { checkoutUrl } = await buyProduct({ id, quantity })
    window.location.href = checkoutUrl
  }

  const filterProducts = (categoryId: string) => {
    if (categoryId === 'all') {
      const allProducts = products.map(product => ({
        ...product,
        active: true
      }));
      return setProducts(allProducts)
    }

    const filteredProducts = products.map((product) => {
      if (product.category.id === categoryId) {
        product.active = true
      } else {
        product.active = false
      }
      return product
    })

    setProducts(filteredProducts)
  }

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await getProducts();

      const productCategories: Product['category'][] = Array.from(
        new Set(products.map((product: Product) => JSON.stringify(product.category)))
      ).map((category: any) => JSON.parse(category));

      setCategories(productCategories);

      const productsWithQuantity = products.map((product: Product) => ({
        ...product,
        quantity: 1,
        active: true,
      }))

      setProducts(productsWithQuantity)
    }

    fetchProducts()
  }, [])

  useEffect(() => {
    if (products?.length > 0) {
      setRenderProducts(true)
    }
  }, [products])

  return (
    <div className="flex flex-col p-2 lg:py-10 mx-auto max-w-7xl">
      <TypographyH1 className="text-pretty lg:mb-2">
        Specials
      </TypographyH1>
      {
        !renderProducts && (
          <p>We have no products at this time, but check back soon!</p>
        )
      }
      {
        categories?.length > 0 && (
          <Select onValueChange={(category: string) => filterProducts(category)}>
            <SelectTrigger className="mb-3 w-[180px]">
              <SelectValue placeholder="Select a Category" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="all" className="cursor-pointer">All Categories</SelectItem>
                {
                  categories?.length > 0 && categories?.map((category) => (
                    <SelectItem
                      key={category.id}
                      value={category.id}
                      className="cursor-pointer"
                    >
                      {category.name}
                    </SelectItem>
                  ))
                }
              </SelectGroup>
            </SelectContent>
          </Select>
        )
      }
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
        {
          renderProducts && products?.filter(product => product.active).map((product) => (
            <Card className="flex flex-col h-full shadow-lg" key={product?.id}>
              <div className="relative">
                <span className="absolute top-0 left-0 text-sm bg-white text-black m-2 px-2 py-1 rounded">{`${product.facility.name}`}</span>
                <span className="absolute top-0 right-0 text-xl bg-white mr-2 mt-2 px-2 py-1 rounded">{`$ ${product.price}`}</span>
                {
                  product.file ? (
                    <img className="w-full h-[250px] object-cover" src={product.file} />
                  ) : (
                    <img className="w-full h-[250px] object-cover	" src="https://placehold.co/250" />
                  )
                }
              </div>
              <div className="p-3 flex flex-col flex-1">
                <CardHeader className="p-0">
                  <TypographyH2 className="text-xl p-0">
                    {product.name}
                  </TypographyH2>
                </CardHeader>
                <div className="flex mb-2">
                  <Badge
                    variant="default"
                    key={product?.category?.id}
                    className="capitalize font-normal text-xs"
                  >
                    {product?.category?.name}
                  </Badge>
                </div>
                <CardContent className="p-0 flex-1 flex flex-col">
                  <p className="overflow-hidden text-ellipsis line-clamp-3 mb-2">{product.description}</p>
                  {
                    product?.allowMultiple && (
                      <div className="flex justify-between items-center my-3">
                        <Button variant="link" size="icon" onClick={() => updateQuantity('decrement', product)}>
                          <Minus />
                        </Button>
                        <span className="rounded p-2 h-12 w-12 bg-blue-100 text-2xl text-center">
                          {product.quantity}
                        </span>
                        <Button variant="link" size="icon" onClick={() => updateQuantity('increment', product)}>
                          <Plus />
                        </Button>
                      </div>
                    )
                  }
                  <div className="flex mt-auto">
                    <Button className="w-full" onClick={() => purchase(product.id, product.quantity)}>Buy</Button>
                  </div>
                </CardContent>
              </div>
            </Card>
          ))
        }
        <LoginDialog
          open={loginDialogIsOpen}
          onOpenChange={setLoginDialogIsOpen}
          onSuccess={() =>  setLoginDialogIsOpen(false)}
        />
      </div>
    </div>
  )
}
