import TypographyH1 from "@/components/ui/typography/h1"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { useQuery } from "@tanstack/react-query"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import * as contentful from "contentful"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { formatDateTime } from './utils';
import { Link, useLocation } from "react-router-dom"
import { TypeBlogPageSkeleton } from "./types";
import { MetaHead } from "@/components/custom/Meta/Head"

interface RecommendedPostProps {
  post: TypeBlogPageSkeleton
}

const client = contentful.createClient({
  space: import.meta.env.VITE_CONTENTFUL_SPACE_ID,
  accessToken: import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN,
  environment: import.meta.env.VITE_CONTENTFUL_ENVIRONMENT,
})

const RENDER_OPTIONS = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node: any, children: React.ReactNode) => <TypographyH1 className="mt-10">{children}</TypographyH1>,
    [BLOCKS.HEADING_2]: (_node: any, children: React.ReactNode) => <TypographyH2 className="mt-10">{children}</TypographyH2>,
    [BLOCKS.HEADING_3]: (_node: any, children: React.ReactNode) => <TypographyH3 className="mt-10">{children}</TypographyH3>,
    [BLOCKS.HEADING_4]: (_node: any, children: React.ReactNode) => <TypographyH4 className="mt-10">{children}</TypographyH4>,
    [BLOCKS.PARAGRAPH]: (_node: any, children: React.ReactNode) => <p className="mt-4">{children}</p>,
    [BLOCKS.OL_LIST]: (_node: any, children: React.ReactNode) => <ol className="mt-4 ml-4 list-decimal">{children}</ol>,
    [BLOCKS.UL_LIST]: (_node: any, children: React.ReactNode) => <ul className="mt-4 ml-4 list-disc">{children}</ul>,
    [BLOCKS.LIST_ITEM]: (node: any, children: React.ReactNode) => {
      // Unwrap paragraphs inside list items
      const unTaggedChildren = documentToReactComponents(node, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (_node, children) => children, // Remove <p> tags inside <li>
        },
      });
      return unTaggedChildren;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: React.ReactNode) => {
      const videoUrl = node.data.target.fields.youtubeUrl
      const embedUrl = videoUrl.includes('shorts') 
        ? videoUrl.replace('shorts/', 'embed/')
        : `https://www.youtube.com/embed/${videoUrl.match(/v=([^&]*)/)?.[1]}`

      if (!videoUrl) return <p>Invalid video URL</p>;

      return (
        <div className="mt-8" style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
          <iframe
            src={`${embedUrl}?controls=0`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube"
          />
        </div>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any, children: React.ReactNode) => {
      return (
        <div className="mt-8" style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
          <iframe
            src={node.data.target.fields.file.url}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video"
          />
        </div>
      )
    },
    [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => <a className="text-blue-700" href={node.data.uri}>{children}</a>,
  },
};

const BlogPostPage = () => {
  const location = useLocation();
  const { pathname } = location;
  const id = pathname.split('/').pop();

  const { data, isPending, error } = useQuery({
    queryKey: ["posts", id],
    queryFn: () => client.getEntries<TypeBlogPageSkeleton, "blogPage" >({
      content_type: "blogPage",
      'fields.slug': id,
      limit: 1,
    }),
  })

  if (isPending) return <Loading />

  if (error) return <Fallback />

  function RecommendedPost({ post }: RecommendedPostProps) {
    return (
      <div className="flex flex-col mb-16 lg:mb-0">
        {/* @ts-ignore */}
        <Link to={`/blog/${post.fields?.slug}`}>
          <div className="aspect-square w-full">
            <img
              alt={String(post.fields?.title)}
              className="w-full h-full object-cover rounded-md"
              // @ts-ignore
              src={post.fields?.image?.fields.file.url}
            />
          </div>
          <p className="mt-6 text-sm font-normal">
            {String(post.fields?.title)}
          </p>
        </Link>
      </div>
    )
  }

  return (
    <>
      <MetaHead
        title={`${data.items[0].fields.metaTitle || data.items[0].fields.title} | Spotsurfer`}
        description={data.items[0].fields.metaDescription}
        url={`https://spotsurfer.com/blog/${data.items[0].fields.slug}`}
        // @ts-ignore
        image={data.items[0]?.fields?.metaImage?.fields?.file?.url}
        imageAlt={data.items[0]?.fields?.metaImageAltText}
      />
      <div className="m-auto px-4 py-20 text-left h-fit max-w-screen-md gap-6">
        <TypographyH1 className="mb-14">{data.items[0].fields.title}</TypographyH1>
        <>
          <img
            alt={String(data.items[0].fields?.title)}
            className="aspect-square rounded-md object-cover m-auto lg:h-96 h-52 w-full my-6"
            // @ts-ignore
            src={data.items[0].fields?.image?.fields.file.url}
          />
          <p className="leading-7 mt-4 mb-10 text-gray-800 font-light text-sm">{formatDateTime(data.items[0].fields?.backDate ? data.items[0].fields.backDate : data.items[0].sys.createdAt)}</p>
          {data.items[0].fields.body && documentToReactComponents(data.items[0].fields.body, RENDER_OPTIONS)}
        </>
        {data.items[0].fields?.recommendedPosts && (
          <>
            <p className="mt-16 mb-4 text-base font-semibold">
              Related Posts:
            </p>

            {data.items[0].fields?.recommendedPosts.length > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
                {data.items[0].fields?.recommendedPosts.map((post: any) => (
                <RecommendedPost key={post.sys.id} post={post} />
                ))}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}

export default BlogPostPage
