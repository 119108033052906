import { cn } from "@/lib/utils"
import type { ReactNode } from "react"
interface TypographyH4Props {
  children: ReactNode
  className?: string
}

export function TypographyH4({ children, className }: TypographyH4Props) {
  return (
    <h4
      className={cn(
        "scroll-m-20 text-xl font-semibold tracking-wide",
        className
      )}>
      {children}
    </h4>
  )
}
