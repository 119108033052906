import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router";
import { format } from "date-fns"
import { getProductOrderById } from "@/api/get"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import TypographyH1 from "@/components/ui/typography/h1"
import { Product } from "@/types/Product"

export const MarketPlaceConfirmationPage = () => {
  const { id } = useParams()

  const { data, isError } = useQuery({
    queryKey: ["order", id],
    queryFn: () => getProductOrderById(id)
  })

  if (!data) {
    return(<>Loading...</>)
  }

  return (
    <div className="p-6 max-w-5xl mx-auto lg:py-10">
      <div className="flex flex-col gap-6">
        <hgroup className="flex flex-col gap-3">
          <TypographyH1 className="text-xl md:text-2xl lg:text-4xl">
            Purchase Confirmed!
          </TypographyH1>
          <p>
            Order Confirmation: {data?.id}
          </p>
        </hgroup>

        <Card>
          <CardHeader>
            <CardTitle>Thank you for ordering with Spotsurfer.</CardTitle>
            <CardDescription>
              You can view your receipt, provided by Stripe,{" "}
              <a
                className="text-accent-foreground underline"
                href={data?.receipt}>
                here
              </a>
              .
            </CardDescription>
          </CardHeader>
          <Separator />
          <CardContent>
            <div className="grid pt-6">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Amount</p>
                  <p>${data?.finalAmount}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Order Date</p>
                  <p>
                    {format(
                      data?.createdDatetime ?? "",
                      "MMMM dd, yyyy"
                    )}
                  </p>
                </div>
              </div>
              {
                data?.products?.map((product: Product) => (
                    <div key={product.id} className="grid gap-3 mt-5">
                      <CardTitle className="text-lg">
                        {product?.name} - <span className="text-lg">({product?.facility?.name})</span>
                      </CardTitle>
                      <CardHeader className="relative p-0 gap-3">
                        {
                          product?.file && (
                            <img className="w-[150px] h-[150px] object-cover" src={product.file} />
                          )
                        }
                        <CardDescription>
                          {product?.description}
                        </CardDescription>
                      </CardHeader>
                    </div>
                  )
                )
              }
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
