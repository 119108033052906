import "./sentry"
import React from "react"
import ReactDOM from "react-dom/client"
import "@/index.css"
import { AuthContextProvider } from "@/context/AuthContext"
import App from "@/App"
import { Helmet, HelmetProvider } from 'react-helmet-async';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </HelmetProvider>
  </React.StrictMode>
)
