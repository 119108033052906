import DashboardFrame from "@/components/custom/DashboardFrame"
import { userRoles } from "@/constants/constants"
import { useAuth } from "@/context/AuthContext"
import { Navigate } from "react-router-dom"
import ParkerReservationsPage from "../ParkerReservationsPage/ParkerReservationsPage"
import ParkerWalletPage from "./ParkerWalletPage"
import ReservationDetailsPage from "./ReservationDetailsPage"
import SupportPage from "../SupportPage"
import SettingsPage from "../SettingsPage"
import AccountSettingsPage from "../AccountSettingsPage"
import AccountVehiclesPage from "../AccountVehiclesPage"
import NotFound404Page from "../NotFound404Page"

const parkerMenuItems = [
  {
    name: "Reservations",
    icon: "calendar-days",
    path: "/parker-dashboard",
    canDisplay: true
  },
  {
    name: "Wallet",
    icon: "wallet",
    path: "/parker-dashboard/wallet",
    canDisplay: true
  },
  {
    name: "Settings",
    icon: "settings",
    path: "/parker-dashboard/settings",
    canDisplay: true
  },
  {
    name: "Support",
    icon: "circle-help",
    path: "/parker-dashboard/support",
    canDisplay: true,
    divider: true
  },
]

const parkerSettingsItems = [
  {
    title: "Account",
    path: "/parker-dashboard/settings"
  },
  // {
  //   title: "Change Password",
  //   path: "/parker-dashboard/settings/change-password"
  // },
  {
    title: "Vehicles",
    path: "/parker-dashboard/settings/vehicles"
  }
]

const ParkerPrivateRoutes = () => {
  const { user } = useAuth()

  const allowedRoles = [userRoles.PARKER, userRoles.STAFF]

  return allowedRoles.includes(user?.role) ?
      <DashboardFrame menuItems={parkerMenuItems} />
    : <Navigate to="/login" />
}

export const ParkerRoutes = (path: string, auth: any) => {
  const { user, accessToken } = useAuth()

  return {
    path,
    element: <ParkerPrivateRoutes />,
    children: [
      {
        index: true,
        element: <ParkerReservationsPage />
      },
      {
        path: "reservation/:id",
        element: <ReservationDetailsPage />
      },
      {
        path: "support",
        element: <SupportPage />
      },
      {
        path: "wallet",
        element: <ParkerWalletPage parker={{ parkerId: user?.parkerId, accessToken }} />
      },
      {
        path: "settings",
        element: <SettingsPage sideBarNavItems={parkerSettingsItems} />,
        children: [
          {
            index: true,
            element: <AccountSettingsPage
              isParker={user?.role === userRoles.PARKER}
            />
          },
          {
            path: "vehicles",
            element: <AccountVehiclesPage />
          }
          // {
          //   path: "change-password",
          //   element: <AccountChangePasswordPage />
          // }
        ]
      },
      {
        path: "*",
        element: <NotFound404Page />
      }
    ]
  }
}
