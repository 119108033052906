import { useState } from "react"
import { Separator } from "@/components/ui/separator"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { NewVehicleForm } from "./NewVehicleForm"
import {
  FormPhoneNumberInput,
  FormTextInput
} from "@/components/custom/Forms/FormInputs"
import { useReservationForm } from "../../context/ReservationFormContext"
import { Checkbox } from "@/components/ui/checkbox"

export const AnonymousParkerForm = () => {
  const { form, control } = useReservationForm()
  const [isRental, setIsRental] = useState(false)

  if (!control) {
    return <></>
  }

  const handleRentalChange = (checked: boolean) => {
    form.setValue("isRental", checked)
    setIsRental(checked)
    if (checked) {
      form.setValue("vehicle", undefined)
      form.clearErrors("vehicle")
    }
    // Always trigger both fields to ensure proper form state
    form.trigger(["isRental", "vehicle"])
  }

  return (
    <>
      <FormTextInput
        control={control}
        name="email"
        type="email"
        label="Email *"
        placeholder="Your Email"
      />
      <FormPhoneNumberInput
        control={control}
        name="phone"
        type="phone"
        label="Phone *"
        placeholder="Phone Number"
      />
      <Separator className="mt-2"/>
      <TypographyH3 className="text-md font-semibold">Vehicle</TypographyH3>
      <label htmlFor="isRental" className="flex items-center gap-2">
        Rental Vehicle
        <Checkbox
          checked={form.watch("isRental") || false}
          onCheckedChange={handleRentalChange}
          id="isRental"
        />
      </label>
      {
        !isRental && (
          <NewVehicleForm />
        )
      }
    </>
  )
}
