import { cn } from "@/lib/utils"
import type { ReactNode } from "react"

interface TypographyH2Props {
  children: ReactNode
  className?: string
}

export function TypographyH2({ children, className }: TypographyH2Props) {
  return (
    <h2
      className={cn(
        "scroll-m-20 pb-2 text-3xl font-semibold tracking-wide",
        className
      )}>
      {children}
    </h2>
  )
}
