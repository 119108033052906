import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/components/ui/carousel"
import { v4 as uuidv4 } from "uuid"
import { Dot } from "lucide-react"

const SpotLotDetailsCard = ({ data }) => {
  const { spot } = data
  const isSpot = spot ? true : false
  let images = []
  let amenities = []
  let name,
    description,
    floor,
    hasSpaceForBiggerVehicles,
    maxHeightSupported,
    parkerInstructions

  if (isSpot) {
    const {
      images: spotImages,
      spot: {
        name: spotName,
        amenities: spotAmenities,
        description: spotDescription,
        floor: spotFloor,
        hasSpaceForBiggerVehicles: spotSpaceForBiggerVehicles,
        maxHeightSupported: spotMaxHeightSupported,
        parkerInstructions: spotParkerInstructions
      }
    } = data
    images = spotImages ? spotImages : []
    name = spotName
    amenities = spotAmenities
    description = spotDescription
    floor = spotFloor
    hasSpaceForBiggerVehicles = spotSpaceForBiggerVehicles
    maxHeightSupported = spotMaxHeightSupported
    parkerInstructions = spotParkerInstructions
  } else {
    const {
      lot: {
        name: lotName,
        description: lotDescription,
        floor: lotFloor,
        hasSpaceForBiggerVehicles: lotSpaceForBiggerVehicles,
        maxHeightSupported: lotMaxHeightSupported,
        parkerInstructions: lotParkerInstructions
      }
    } = data
    name = lotName
    description = lotDescription
    floor = lotFloor
    hasSpaceForBiggerVehicles = lotSpaceForBiggerVehicles
    maxHeightSupported = lotMaxHeightSupported
    parkerInstructions = lotParkerInstructions
  }

  const carouselImages = images.map(({ file, name }) => (
    <CarouselItem className="max-w-80" key={uuidv4()}>
      <img
        alt={name}
        src={file}
        className="aspect-square rounded-md object-cover"
      />
    </CarouselItem>
  ))

  const amenityItems = amenities.map((amenity) => {
    return (
      <p
        key={uuidv4()}
        className="text-sm font-semibold">
        <Dot className="w-4 h-4 inline" />
        {amenity.name}
      </p>
    )
  })

  return (
    <Card className="max-w-3xl">
      <CardHeader>
        <CardTitle>Spot Details for {name}</CardTitle>
        {/* <CardDescription className="pt-2">{description}</CardDescription> */}
      </CardHeader>
      <CardContent className={`grid gap-4 ${isSpot ? "grid-cols-2" : ""}`}>
        {carouselImages.length > 0 && (
          <Carousel className="w-2/3 ml-10">
            <CarouselContent>{carouselImages}</CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        )}
        <div className="grid gap-2">
          {floor && (
            <div>
              <p className="font-semibold">Floor Number: </p>
              <p className="text-sm">
                {floor}
              </p>
            </div>
          )}
          <div>
            <p className="font-semibold">
              Has Space For Bigger Vehicles:{" "}
            </p>
            <p className="text-sm">
              {hasSpaceForBiggerVehicles ? "✅" : "❌"}
            </p>
          </div>
          {maxHeightSupported && (
            <div>
              <p className="font-semibold">Max Height Supported:</p>
              <p className="text-sm">
                {maxHeightSupported}
              </p>
            </div>
          )}
          {parkerInstructions && (
            <div>
              <p className="font-semibold">Parker Instructions:</p>
              <p className="text-sm">
                {parkerInstructions}
              </p>
            </div>
          )}
          {amenityItems.length > 0 && (
            <div>
              <p>Amenities:</p>
              {amenityItems}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default SpotLotDetailsCard
