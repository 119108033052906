import { VehicleForm } from "./const"
import { Separator } from "@/components/ui/separator"
import { useState } from "react"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { ExistingVehicleDropdown } from "./ExistingVehicleDropdown"
import { Button } from "@/components/ui/button"
import { ChevronLeft, Plus } from "lucide-react"
import { NewVehicleForm } from "./NewVehicleForm"
import { InputSkeleton } from "@/components/custom/Forms/Skeletons"
import { Skeleton } from "@/components/ui/skeleton"
import { useReservationForm } from "../../context/ReservationFormContext"
import { Checkbox } from "@/components/ui/checkbox"
import { FormPhoneNumberInput, FormTextInput } from "@/components/custom/Forms/FormInputs"

export const ExistingParkerForm = () => {
  const [showNewVehicleForm, setShowNewVehicleForm] = useState(false)
  const [isRental, setIsRental] = useState(false)
  const { control, form, parker } = useReservationForm()

  if (!form || !control) {
    return <></>
  }

  const handleRentalChange = (checked: boolean) => {
    form.setValue("isRental", checked)
    setIsRental(checked)
    if (checked) {
      form.setValue("vehicle", undefined)
      form.clearErrors("vehicle")
    }
    // Always trigger both fields to ensure proper form state
    form.trigger(["isRental", "vehicle"])
  }

  return (
    <>
      { (!parker?.email || !parker?.phone) && (<TypographyH3 className="text-md font-semibold">Contact Info *</TypographyH3>) }
      {
        !parker?.email && (
          <div className="mb-2">
            <FormTextInput
              control={control}
              name="email"
              type="email"
              label="Email *"
              placeholder="Your Email"
            />
          </div>
        )
      }
      {
        !parker?.phone && (
          <div className="mb-2">
            <FormPhoneNumberInput
              control={control}
              name="phone"
              type="phone"
              label="Phone *"
              placeholder="Phone Number"
            />
          </div>
        )
      }
      <TypographyH3 className="text-md font-semibold">Vehicle *</TypographyH3>
      <label htmlFor="isRental" className="flex items-center gap-2">
        Rental Vehicle
        <Checkbox
          checked={form.watch("isRental") || false}
          onCheckedChange={handleRentalChange}
          id="isRental"
        />
      </label>
      {
        !isRental && (
          (showNewVehicleForm || !parker?.vehicles) || (parker.vehicles.length === 0) ?
            <>
              {!parker?.vehicles || parker.vehicles.length === 0 || (
                <Button
                  type="button"
                  className="w-fit"
                  variant="outline"
                  onClick={() => {
                    form?.setValue("vehicle", {} as VehicleForm)
                    setShowNewVehicleForm(false)
                  }}>
                  <ChevronLeft className="h-4 w-4" /> Use an existing vehicle
                </Button>
              )}
              <NewVehicleForm />
            </>
          : <div className="flex flex-wrap md:flex-nowrap gap-2">
              <ExistingVehicleDropdown />
              <Button
                type="button"
                variant="link"
                className="flex w-full md:w-auto underline"
                onClick={() => {
                  form?.setValue("vehicle", {} as VehicleForm)
                  setShowNewVehicleForm(true)
                }}>
                <Plus className="h-4 w-4" /> New Vehicle
              </Button>
            </div>
        )
      }
    </>
  )
}

export const ExistingParkerSkeleton = () => (
  <>
    <Skeleton className="h-5 w-full" />
    <InputSkeleton />
    <InputSkeleton />
    <Separator />
    <Skeleton className="h-5 w-full" />
    <InputSkeleton />
    <Skeleton className="h-10 w-48" />
    <Skeleton className="h-5 w-full" />
  </>
)
