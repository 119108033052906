import type { FormEvent } from "react"
import { useState } from "react"
import { Check, Copy, X } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card, CardContent, CardTitle, CardFooter } from "@/components/ui/card"
import skiResortPeeps from "@/assets/pngs/skiResortPeeps.jpg"
import skiResortPeepsMobile from "@/assets/pngs/skiResortPeepsMobile.png"

interface PromoContent {
  id: string
  title: string
  description: string
  code: string
  discount: string
  buttonText: string
  terms?: string
}

// Map of all available promos
const PROMO_CONTENT: Record<string, PromoContent> = {
  newUser: {
    id: "newUser",
    title: "Take 20% off!",
    description:
      "Sign up now and receive an exclusive discount on your first parking reservation!",
    code: "NEW20",
    discount: "20%",
    buttonText: "Get My Discount",
    terms:
      "Valid for first-time users only. Cannot be combined with other offers."
  },
  seasonal: {
    id: "seasonal",
    title: "Holiday Special!",
    description: "Book your holiday parking in advance and save big!",
    code: "HOLIDAY30",
    discount: "30%",
    buttonText: "Claim Offer",
    terms: "Valid for bookings between Dec 1 - Jan 15."
  },
  referral: {
    id: "referral",
    title: "Refer & Earn",
    description:
      "Share with friends and both get a discount on your next booking!",
    code: "REFER15",
    discount: "15%",
    buttonText: "Share & Save",
    terms: "Both referrer and referee must be registered users."
  }
} as const

type PromoType = keyof typeof PROMO_CONTENT

interface PromoPopupProps {
  promoType: PromoType
  onClose: () => void
  onSubmit: (e: FormEvent) => Promise<void>
  email: string
  setEmail: (email: string) => void
}

export function PromoPopup({
  promoType = "newUser",
  onClose,
  onSubmit,
  email,
  setEmail
}: PromoPopupProps) {

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showCode, setShowCode] = useState(false)

  const promoData = PROMO_CONTENT[promoType]

  const handleEmailSubmit = async (e: FormEvent) => {
    setIsSubmitting(true)

    try {
      await onSubmit(e)
      setShowCode(true)
    } catch (error) {
      console.error("Error submitting email:", error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/60 p-2 sm:p-4">
      <Card className="relative w-[95%] sm:w-full max-w-2xl border-none overflow-hidden h-[550px] sm:h-[500px]">
        <button
          onClick={onClose}
          className="absolute right-2 top-2 sm:right-4 sm:top-4 text-gray-500 hover:text-gray-700 rounded-full bg-white"
          aria-label="Close popup">
          <X size={24} />
        </button>
        <div className="flex flex-col sm:flex-row h-full">
          <div className="w-full sm:w-1/2 sm:h-full overflow-clip">
            <picture>
              <source media="(min-width: 640px)" srcSet={skiResortPeeps} />
              <source
                media="(max-width: 500px)"
                srcSet={skiResortPeepsMobile}
              />
              <img
                src={skiResortPeeps}
                alt="Promo Image"
                className="w-full h-full object-cover sm:rounded-l-md"
              />
            </picture>
          </div>
          <div className="flex w-full sm:w-1/2 flex-col justify-around p-4 sm:p-6 h-full">
            {!showCode ?
              <PromoIntro
                promoData={promoData}
                handleSubmit={handleEmailSubmit}
                email={email}
                setEmail={setEmail}
                isSubmitting={isSubmitting}
              />
            : <PromoCoupon promoData={promoData} />}
          </div>
        </div>
      </Card>
    </div>
  )
}

interface PromoIntroProps {
  promoData: PromoContent
  handleSubmit: (e: FormEvent) => void
  email: string
  setEmail: (email: string) => void
  isSubmitting: boolean
}

const PromoIntro = ({
  promoData,
  handleSubmit,
  email,
  setEmail,
  isSubmitting
}: PromoIntroProps) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div className="h-full flex flex-col justify-center">
        <CardTitle className="text-center text-3xl sm:text-4xl font-bold mb-6 sm:mb-12">
          {promoData.title}
        </CardTitle>
        <CardContent className="p-0">
          <p className="text-center text-gray-600 mb-4 sm:mb-4 text-sm">
            {promoData.description}
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full"
            />
            <Button
              type="submit"
              variant="orange"
              className="w-full"
              disabled={isSubmitting}>
              {isSubmitting ? "Sending..." : promoData.buttonText}
            </Button>
          </form>
        </CardContent>
      </div>
      <CardFooter className="p-0">
        <div className="space-y-2 text-center w-full">
          {/* {promoData.terms && (
            <p className="text-xs text-gray-500">{promoData.terms}</p>
          )} */}
          <p className="text-[10px] text-gray-500">
            By proceeding, you agree to our{" "}
            <a href="/terms-and-conditions" className="text-blue-500">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/terms-and-conditions" className="text-blue-500">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </CardFooter>
    </div>
  )
}

interface PromoCouponProps {
  promoData: PromoContent
}

const PromoCoupon = ({ promoData }: PromoCouponProps) => {
  return (
    <div className="text-center space-y-6">
      <CardTitle className="text-2xl sm:text-4xl font-bold">All set!</CardTitle>
      <CardContent className="p-0">
        <div className="space-y-6">
          <p className="text-gray-600">
            Use this code at checkout to get {promoData.discount} off your next
            booking
          </p>
          <PromoCode code={promoData.code} />
        </div>
      </CardContent>
    </div>
  )
}

export const PromoCode = ({ code }: { code: string }) => {
  const [isCopied, setIsCopied] = useState(false)

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(code)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy code:", err)
    }
  }

  return (
    <div className="flex flex-col items-left space-y-4">
      <p className="text-sm text-gray-500">Apply code at checkout</p>
      <PromoCodeButton code={code} isCopied={isCopied} copyToClipboard={copyToClipboard} />
    </div>
  )
}

const PromoCodeButton = ({ code, isCopied, copyToClipboard }: { code: string, isCopied: boolean, copyToClipboard: () => void }) => {
  return (
    <button
      className="flex hover:bg-gray-100 px-12 py-4 bg-gray-50 rounded-lg justify-center items-center gap-4 border-dashed border-4"
      onClick={copyToClipboard}
      aria-label={isCopied ? "Copied!" : "Copy to clipboard"}>
      <code className="text-2xl font-mono font-bold text-orange-600">
          {code}
        </code>
      {isCopied ?
        <Check className="h-6 w-6 text-green-500" />
      : <Copy className="h-6 w-6 text-gray-500" />}
    </button>
  )
}