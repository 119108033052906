import { PhoneNumberSchema } from "@/constants/PhoneSchema"
import { USStateSchema } from "@/constants/States"
import { VehicleTypeSchema } from "@/constants/VehicleType"
import { z } from "zod"

export const VehicleSchema = z.object({
  make: z.string().max(255),
  model: z.string().max(255),
  type: VehicleTypeSchema,
  color: z.string().max(255).optional(),
  licenseNumber: z.string().min(1).max(8),
  registeredState: USStateSchema,
  isEv: z.coerce.boolean()
})

export type VehicleForm = z.infer<typeof VehicleSchema>

export const ParkerVehicleSchema = z.object({
  email: z.string().email(),
  phone: PhoneNumberSchema,
  vehicle: VehicleSchema.optional(),
  isRental: z.coerce.boolean().default(false)
})

export type ParkerVehicleForm = z.infer<typeof ParkerVehicleSchema>
