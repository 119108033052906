import { useState } from "react"
import * as Sentry from "@sentry/react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider, QueryCache } from "@tanstack/react-query"
import { APIProvider as GoogleMapsApiProvider } from "@vis.gl/react-google-maps"
import { routes } from "@/routes"
import { useAuth } from "@/context/AuthContext"
import { AlertProvider } from "@/context/CustomAlertContext"
import { getRefreshAccessToken } from "@/api/post"
import { LabelProvider } from "./context/LabelContext"
import { DevAuthProvider } from "./context/DevAuthorizer/DevAuthorizerContext"
import { initializeAnalytics } from "@/lib/analytics"

initializeAnalytics()

const App = () => {
  if (
    window.location.protocol == "http:" &&
    location.hostname !== "localhost"
  ) {
    window.location.href = window.location.href.replace("http:", "https:")
  }

  const IS_PROD =
    import.meta.env.VITE_REACT_ENV === "production" ||
    import.meta.env.VITE_REACT_ENV === "local"

  const routerOptions = {
    future: {
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
      v7_startTransition: true,
      v7_fetcherPersist: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  }

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  const router = sentryCreateBrowserRouter(routes(useAuth()), routerOptions)

  const { user, accessToken, refreshToken, setTokens } = useAuth()
  const [refreshingToken, setRefreshingToken] = useState(false)

  const key = import.meta.env.VITE_GOOGLE_MAPS_API

  //FIXME: EXTRACT from component body
  const refreshAuthToken = async () => {
    if (!refreshingToken && refreshToken) {
      try {
        setRefreshingToken(true)
        const refreshedAccessToken = await getRefreshAccessToken(
          accessToken,
          refreshToken
        )
        setTokens({ access: refreshedAccessToken, refresh: refreshToken })
      } catch {
        //FIXME: Need to navigate to /login
        console.log("FIX ME SHOULD NAVIGATE TO /LOGIN")
      } finally {
        setRefreshingToken(false)
      }
    }
  }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 30000, // 30 seconds
            retry: (failureCount, error) => {
              // Don't retry for certain error responses
              if (
                error?.response?.status === 400 ||
                error?.response?.status === 401
              ) {
                return false
              }

              // Retry others just once
              return failureCount <= 1
            }
          }
        },
        queryCache: new QueryCache({
          onError: (error) => {
            if (
              error?.response?.status === 400 ||
              error?.response?.status === 401
            ) {
              refreshAuthToken()
            }
          }
        })
      })
  )

  return (
    <>
      {
        user?.isImpersonating && (
          <p className="fixed flex top-0 z-50 bg-blue-300 w-full justify-center text-xs">
            Impersonating: {user?.parker.email}
          </p>
        )
      }
      <AlertProvider>
        <QueryClientProvider client={queryClient}>
          {IS_PROD ?
            <GoogleMapsApiProvider apiKey={key}>
              <LabelProvider>
                <RouterProvider router={router} future={routerOptions.future} />
              </LabelProvider>
            </GoogleMapsApiProvider>
            : <DevAuthProvider>
              <GoogleMapsApiProvider apiKey={key}>
                <LabelProvider>
                  <RouterProvider router={router} future={routerOptions.future} />
                </LabelProvider>
              </GoogleMapsApiProvider>
            </DevAuthProvider>
          }
        </QueryClientProvider>
      </AlertProvider>
    </>
  )
}

export default App
