import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

// Heap analytics configured in index.html

export function initializeAnalytics() {
    if (import.meta.env.VITE_ENABLE_ANALYTICS === "false") {
        return;
    }

    const sessionReplayTracking = sessionReplayPlugin();
    amplitude.add(sessionReplayTracking);

    amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY);
}