import { format } from "date-fns"
import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { cn, getYearMonthDay, sortFacilities } from "@/lib/utils"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, SelectLabel, SelectGroup } from "@/components/ui/select"
import { useParkingSearchForm } from "@/hooks/useParkingSearchForm"
import { CalendarPopover } from "@/components/custom/CalendarPopover.tsx"
import { Separator } from "@/components/ui/separator"

const ParkSearchForm = ({
  searchQuery,
  handleSearchQuery,
  facilities,
  facilityCollections,
  showTitle = true,
  labelClassName = "",
  isHomePage = false
}) => {
  const { form, facilityOptions, disableSelectionAvailability } = useParkingSearchForm(searchQuery, sortFacilities(facilities))

  const onSubmit = async ({ facility, checkinDate, checkoutDate }) => {
    const checkinDateFormatted = getYearMonthDay(checkinDate)
    const checkoutDateFormatted = getYearMonthDay(checkoutDate)

    const query = {
      facility,
      checkinDate: checkinDateFormatted,
      checkoutDate: checkoutDateFormatted
    }

    handleSearchQuery(query)
  }

  const HomePageForm = () => {
    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col md:flex-row justify-between items-center bg-white rounded-md p-2 w-full md:w-[800px] gap-2">
          {showTitle && (<h2>Book a Spot</h2>)}
          <FormField
            control={form.control}
            name="facility"
            render={({ field }) => (
              <FormItem className="w-full">
                <Select onValueChange={field.onChange} value={field.value} size='5'>
                  <FormControl className="border-none text-base hover:bg-accent transition-colors">
                    <SelectTrigger>
                      <SelectValue placeholder="Vail, Beaver Creek" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="all" className="text-base cursor-pointer">Vail & Beaver Creek</SelectItem>
                      {
                        facilityCollections.map((collection,) => (
                          <div key={collection.id}>
                            <SelectLabel className="ml-[-15px]">
                              {collection.name}
                            </SelectLabel>
                            {collection.facilities.map(({ id, name }) => (
                              <SelectItem value={id} key={name} className="text-base cursor-pointer">
                                {name}
                              </SelectItem>
                            ))}
                          </div>
                        ))
                      }
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <Separator orientation="vertical" className="hidden md:block h-10 bg-gray-300"/>
          <Separator orientation="horizontal" className="md:hidden bg-gray-300"/>
          <FormField
            control={form.control}
            name="dateRange"
            render={({ field }) => (
              <FormItem className="w-full flex">
                <CalendarPopover
                  onChange={(selection) => {
                    form.setValue("checkinDate", selection.checkinDate)
                    form.setValue("checkoutDate", selection.checkoutDate)
                  }}
                  start={form.getValues("checkinDate")}
                  end={form.getValues("checkoutDate")}
                  disabled={disableSelectionAvailability}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" variant="orange" className="w-full lg:w-[unset] hover:bg-orange-500 transition-colors duration-500 text-base">
            Search
          </Button>
        </form>
      </Form>
    )
  }

  return (
    <>
      {
        isHomePage
          ? (<HomePageForm />)
          : (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="py-3 space-y-3 lg:space-y-6 lg:py-6 rounded-xl max-h-content">
                {showTitle && (
                  <h2 className="scroll-m-20 border-b pb-2 text-2xl font-semibold tracking-wide first:mt-0 lg:text-3xl">
                    Book a Spot
                  </h2>
                )}
                <div className="grid gap-3">
                  <FormField
                    control={form.control}
                    name="facility"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className={labelClassName}>
                          Parking Facility
                        </FormLabel>
                        <Select onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a parking facility" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {facilityOptions.map(({ id, name }) => (
                              <SelectItem value={id} key={id}>
                                {name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="dateRange"
                    render={({ field }) => (
                      <FormItem className="w-full md:w-auto">
                        <CalendarPopover
                          onChange={(selection) => {
                            form.setValue("checkinDate", selection.checkinDate)
                            form.setValue("checkoutDate", selection.checkoutDate)
                          }}
                          start={form.getValues("checkinDate")}
                          end={form.getValues("checkoutDate")}
                          disabled={disableSelectionAvailability}
                          showPopoverBorder={true}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <Button type="submit" variant="orange" size="lg" className="w-full lg:w-[unset] hover:bg-orange-500 transition-colors duration-500">
                  Search
                </Button>
              </form>
            </Form>
          )
      }
    </>
  )
}

export default ParkSearchForm
