import { MarketPlacePage } from "@/pages/Market/Page"
import { MarketPlaceConfirmationPage } from "@/pages/Market/Confirmation"
import { MarketPlaceFailurePage } from "@/pages/Market/Failure"
import PublicLayout from "@/components/custom/PublicLayout"
import { RouteObject } from "react-router-dom"

export const MarketRoutes: RouteObject[] = [
  {
    path: "market",
    element: (
      <PublicLayout>
        <MarketPlacePage />
      </PublicLayout>
    )
  },
  {
    path: "market/confirmation/:id",
    element: (
      <PublicLayout>
        <MarketPlaceConfirmationPage />
      </PublicLayout>
    )
  },
  {
    path: "market/failed",
    element: (
      <PublicLayout>
        <MarketPlaceFailurePage />
      </PublicLayout>
    )
  },
]
